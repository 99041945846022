/* TAILWIND-CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* GOOGLE-FONT */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@font-face {
    font-family: "blacksword";
    src: url("/public/fonts/blacksword.otf");
}
/* GLOBAL-STYLE */
* {
    font-family: "Montserrat", sans-serif;
}

/* SWEETALERT2 */
.swal2-title {
    margin-top: -1.05rem;
}

.swal2-icon {
    margin-top: 1.45rem;
}

.swal2-confirm {
    margin-top: -0.05rem;
}

.swal2-container {
    z-index: 20000;
    /*for show sweetalert2 over the mui dialog*/
}

/* DATE-PICKER */
.position_center_absulate {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.no-date-picker-icon::-webkit-inner-spin-button,
.no-date-picker-icon::-webkit-clear-button {
    display: none;
}

/* BODY */
body {
    background-color: #f8f7f7 !important;
}

/* SWIPER */
.swiper {
    width: 50%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: auto;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.remove-arrow {
    -moz-appearance: textfield;
}

.container__for__slider {
    max-width: 100% !important;
    perspective: 1000;
    -webkit-perspective: 1000;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.coverflow__for__slider {
    position: absolute;
    backface-visibility: visible;
    transform: scale(1) translateZ(-400px);
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    cursor: pointer;
}

.coverflow-item__for__slider {
    transform-origin: 50% 50%;
    position: absolute;
    backface-visibility: visible;
    top: -175px;
    left: -150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.5s all ease-out;
    /*Improvement*/
    transition: 1.25s all cubic-bezier(0.17, 0.75, 0.58, 0.95);
}

/* COUPON CARD */
.couponCard {
    position: relative;
    background-size: cover;
}

.couponCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay color with 50% opacity */
    /* extra */
    border-radius: 16px;
    cursor: pointer;
}

/* COUPON CARD BUTTON */
.hoverCouponCard {
    position: relative;
    background-size: cover;
}

.hoverCouponCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Overlay color with 50% opacity */
    /* extra */
    border-radius: 16px;
    cursor: pointer;
}

/* COUPON CARD HOVER BUTTON */
.hoverCouponCard button {
    position: relative;
    background-size: cover;
    color: #f8f8f8;
}

.hoverCouponCard button:hover {
    color: #1d417c;
    background-color: #f8f8f8;
}

.hoverCouponCard button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Overlay color with 50% opacity */
    /* extra */
    border-radius: 16px;
    cursor: pointer;
}

.hoverCouponCard button .svg-icon-stroke {
    stroke: #f8f8f8;
}
.hoverCouponCard button:hover .svg-icon-stroke {
    stroke: #1d417c;
}
.hoverCouponCard button .svg-icon-fill {
    fill: #f8f8f8;
}
.hoverCouponCard button:hover .svg-icon-fill {
    fill: #1d417c;
}

.sigCanvas__ {
    width: 100% !important;
    /* height: 300px; */
    object-fit: contain;
    height: 250px;
    border: 2px solid gray;
    border-radius: 5px;
}

/* EYE HIDE FOR EDGE SAFARAI BROWSER */
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.mat-caption .st-broker-account-registration .ng-star-inserted {
    display: none !important;
}

.st-broker-account-registration {
    display: none !important;
    background-color: red;
}

/* Track */
.latest-news::-webkit-scrollbar {
    width: 5px; /* Width of the entire scrollbar */
}

/* Handle */
.latest-news::-webkit-scrollbar-thumb {
    background-color: #e0e0e0; /* Color of the scrollbar handle */
    border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
.latest-news::-webkit-scrollbar-thumb:hover {
    background-color: #9b9b9b; /* Color of the scrollbar handle on hover */
}

.changeAccount::-webkit-scrollbar {
    width: 5px; /* Width of the entire scrollbar */
}

/* Handle */
.changeAccount::-webkit-scrollbar-thumb {
    background-color: #e0e0e0; /* Color of the scrollbar handle */
    border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
.changeAccount::-webkit-scrollbar-thumb:hover {
    background-color: #9b9b9b; /* Color of the scrollbar handle on hover */
}

/* REACT PHONE NUMBER INPUT */
.react-tel-input {
    font-size: 0.9rem !important;
}

.react-tel-input .form-control {
    height: 3.5rem !important;
    position: relative;
    letter-spacing: 0.01rem;
    border-radius: 10 !important;
    margin-top: 10 !important;
    margin-bottom: 10 !important;
    width: 100% !important;
    font-size: 0.9rem !important;
    outline: none !important;
    box-shadow: none !important;
}

@media only screen and (max-width: 640px) {
    .react-tel-input .form-control {
        height: 2.5rem !important;
    }
}

@media (max-width: 479px) {
    .complycube-sdk-ui-Welcome-icon {
        padding: 6em 0 6em 0 !important;
    }

    .complycube-sdk-ui-Modal-inner {
        height: 21em !important;
    }
}

.complycube-sdk-ui-Theme-scrollableContent {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    height: auto;
}

.complycube-sdk-ui-Modal-inner {
    height: 25em;
    width: auto;
    position: relative;
}

.complycube-sdk-ui-Uploader-contentWrapper,
.complycube-sdk-ui-Uploader-uploaderWrapper {
    height: 22em;
}

.complycube-sdk-ui-Theme-footer,
.complycube-sdk-ui-NavigationBar-navigation,
.complycube-sdk-ui-PageTitle-titleWrapper,
.complycube-sdk-ui-Welcome-text {
    display: none;
}

.complycube-sdk-ui-Welcome-icon {
    padding: 8em 0 8em 0;
}

.ods-button.-action--primary {
    background-color: #8dc63f;
    border-color: #7eaf31;
}

.ods-button.-action--primary:hover {
    background-color: #7eaf31;
}

.complycube-sdk-ui-crossDevice-CrossDeviceLink-qrCodeContainer svg {
    margin: auto;
}

.MuiFormHelperText-root
    .MuiFormHelperText-sizeMedium
    .MuiFormHelperText-contained
    .MuiFormHelperText-filled
    .css-2dnmjy-MuiFormHelperText-root {
    margin-left: 0px !important;
}


/* data spike override classes */

#dataspike .content-wrapper {
    height: 100% !important;
}

#dataspike .title {
    font-size: 18px;
    margin-top: 0 !important;
}

#dataspike .time span {
    font-size: 16px;
    color: #7eaf31 !important;
}

#dataspike .main-content-container h3 {
    line-height: 0 !important;
}

#dataspike .sc-bJBgwP {
    padding: 10px 10px !important;
    min-height: 100% !important;
}

#dataspike .iPrkNN .info-icon {
    color: #7eaf31 !important;
}

#dataspike .sc-gEvEer {
    background-color: #7eaf31 !important;
    color: #FFF !important;
}
#dataspike .ePaWJd {
    background-color: #FFF !important;
    color: #7eaf31 !important;
}
#dataspike .card__number {
    background-color: #7eaf31 !important;
}

.ePaWJd {
    border-bottom: 1px dashed #7eaf31 !important;
}

#dataspike a,
#dataspike .jdtvdO.Mui-checked{
    color: #7eaf31 !important;
}


.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

#dataspike .card__number {
    margin: 0 auto !important;
    width: 16px !important;
    padding: 5px !important;
    font-size: 14px;
}

#dataspike .card__description {
    margin-top: 5px !important;
}

#dataspike .sc-dGCmGc {
    padding-top: 16px;
}

#dataspike .indicator-container .css-10twnsm {
    color: #7eaf31 !important;
}

#dataspike .indicator-container .css-mq3aek {
    color: #8ec63fa7 !important;
}

#dataspike .links-container > .card:last-child {
    display: none !important;
}

.jdtvdO {
    color: #7eaf31 !important;
}

@media (max-width: 767px) {
    .ZptXg .time {
        color: #7eaf31 !important;
    }
}

.kpEGsJ,
.eXMHEQ {
    border: none !important;
}

.hsmTMq .tab__item.current-tab .MuiButtonBase-root {
    color: #7eaf31 !important;
}

.hsmTMq .tab__item.current-tab::after {
    background: #7eaf31 !important;
}

.fmPjnx {
    background: rgb(234, 241, 254);
    border: 2px dashed #7eaf31 !important;
}

.kUkQIE {
    min-height: auto !important;
}

.bAHSfi.error {
    border-color: #7eaf31 !important;
}